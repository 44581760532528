<template>
  <div style="background:#eee;min-height:100%;">
    <!-- <van-nav-bar title="网上办事" /> -->

    <!-- 列表 -->
    <div class="header">
      <div class="personal">
        <div class="special"
          style="width:95%;margin: auto;border-radius:5px;">
          <div class="borderClass">
            <van-cell :key="index"
              is-link
              style="width:95%;margin:auto;font-size:17px;color:#333333;line-height: 30px;"
              v-for="(item, index) in NWKindList"
              @click="goWork(item)">
              <!-- :to="'/work/' + item.NWKId" -->
              <img :src="item.AppletIconUrl"
                class="linkicon" />{{item.Name}}</van-cell>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { WeGetNWKindList } from "@/api/Network";

export default {
  components: {},
  data () {
    return {
      NWKindList: [],
    };
  },
  created () {
    this.getNWKindList();
  },
  methods: {
    goWork (scop) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../home/homeList/index?NWKId=" + scop.NWKId,
        });
      } else {
        alert("请在小程序中打开");
      }
    },
    // 获取办事类型列表
    getNWKindList () {
      WeGetNWKindList().then((res) => {
        this.NWKindList = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.header {
  /* position: relative;
  height: 120px; */
  /* margin-bottom: 80px; */
  /* background-image: url('https://api.qxzhqm.cn//upload/images/2022/5/185a1721-af0.png');
  background-size: 100% 50%;
  background-repeat: no-repeat; */
  position: absolute;
  background: linear-gradient(#f63e3b, #eee);
  /* background: #f63e3b; */
  width: 100%;
  height: 180px;
}
.personal {
  width: 100%;
  align-items: center;
  text-align: center;
  /* background-color: #eee; */
  /* position: absolute;
  top: 10px; */
}
.borderClass {
  border-radius: 5px;
  margin: auto;
  width: 100%;
  background: #fff;
}
</style>
<style>
.special {
  padding: 25px 10px 10px 10px;
}
.special img {
  position: relative;
  top: 30%;
  margin-top: -10px;
  width: 24px;
  height: 24px;
}
</style>